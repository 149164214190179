// src/Game.js
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const socket = io('http://193.160.119.72:5050');

const Game = () => {
    const [position, setPosition] = useState([51.505, -0.09]);
    const [players, setPlayers] = useState({});
    const [tracking, setTracking] = useState(false);
    const [watchId, setWatchId] = useState(null);

  useEffect(() => {
    if (tracking) {

        const updatePosition = (pos) => {
            const { latitude, longitude } = pos.coords;
            console.log(`Aktualna pozycja: Latitude ${latitude}, Longitude ${longitude}`);
            setPosition([latitude, longitude]);
            socket.emit('updatePosition', { latitude, longitude });
        };


      if (navigator.geolocation) {
        const id = navigator.geolocation.watchPosition(
          updatePosition,
          (err) => {
            console.error('Błąd przy pobieraniu lokalizacji:', err);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
        setWatchId(id);
      } else {
        console.log('Geolokalizacja nie jest wspierana przez tę przeglądarkę.');
      }
    } else {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
        setWatchId(null);
      }
    }

    socket.on('playerMoved', ({ id, position }) => {
      setPlayers((prev) => ({ ...prev, [id]: position }));
    });

    socket.on('playerDisconnected', (id) => {
      setPlayers((prev) => {
        const updatedPlayers = { ...prev };
        delete updatedPlayers[id];
        return updatedPlayers;
      });
    });

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [tracking]);

  socket.on('connect', () => {
    console.log('Połączono z serwerem!');
    });

    socket.on('connect_error', (err) => {
        console.error('Błąd połączenia:', err);
    });

    socket.on("disconnect", () => {
        setTimeout(function () {
            socket.emit("disconnected")
            socket.leave()
        }, 10000);  
    })

  const toggleTracking = () => {
    setTracking((prev) => !prev);
    console.log(tracking)
  };

  const clicktest = () => {

    console.log("start")


  }

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
      <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>Twoja pozycja</Popup>
        </Marker>
      </MapContainer>
      <button
        style={{
          position: 'absolute',
          top: 20,
          left: 20,
          padding: '10px 20px',
          fontSize: '16px',
          zIndex: 1000,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
        onClick={toggleTracking}
      >
        {tracking ? 'Wyłącz śledzenie' : 'Włącz śledzenie'}
      </button>
      <div         style={{
          position: 'absolute',
          top: 20,
          left: 300,
          padding: '10px 20px',
          fontSize: '16px',
          zIndex: 1000,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}>{position[0]} , {position[1]}</div>
    </div>

  );
};

export default Game;
