import './App.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from './images/location-marker-icon-15.png'; // Własny obrazek ikony
import Game from './components/game';

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          setPosition([latitude, longitude]);
          map.setView([latitude, longitude], 13);
        },
        (error) => {
          console.error('Error obtaining location:', error);
        }
      );
    }
  }, [map]);

  const customIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [38, 38], // Rozmiar ikony
    iconAnchor: [19, 38], // Punkt zakotwiczenia
    popupAnchor: [0, -38] // Punkt zakotwiczenia popupu
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon} />
  );

}

function App() {

  return (
    <div>
      {/* <MapContainer center={[52.2297, 21.0122]} zoom={13} style={{ height: '100vh', width: '100vw' }}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        <LocationMarker />
      </MapContainer> */}
      <Game />
    </div>
  );
}

export default App;



